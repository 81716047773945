import React, { FC } from 'react';
import { css } from '@emotion/css';
import useTheme from 'evcharging/hooks/useTheme';
import Tippy from '@tippyjs/react';
import { Theme } from 'evcharging/app/themes';

interface InputLabelProps {
  label: string;
  required?: boolean;
  tooltip?: string;
  onClick?: () => void;
  small?: boolean;
}

const InputLabel: FC<InputLabelProps> = (props) => {
  const { label, small = false, required = false, tooltip, onClick } = props;
  const theme = useTheme();
  const styles = createStyles(theme, { small });
  return (
    <label className={styles.root}>
      <div className={styles.label}>
        {label}
        {required && <span className={styles.required}>*</span>}
      </div>
      {tooltip && (
        <Tippy trigger={'click'} content={<div className={styles.tooltip}>{tooltip}</div>}>
          <button onClick={onClick} className={styles.question} tabIndex={-1}>
            ?
          </button>
        </Tippy>
      )}
    </label>
  );
};

const createStyles = (theme: Theme, { small }: { small: boolean }) => ({
  root: css`
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  `,
  label: css`
    font-weight: ${small ? 600 : 500};
    font-size: ${small ? '12px' : '18px'};
    margin-right: 16px;
  `,
  tooltip: css`
    background: #000;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
  `,
  question: css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 17px;
    height: 17px;
    background: ${theme.colors.inactiveAccent};
    color: ${theme.colors.accent};
    font-size: 14px;
    border-radius: 50%;
    border: none;
    outline: none;
    cursor: pointer;
  `,
  required: css`
    display: inline-block;
    margin-left: 3px;
    color: #eb5757;
  `,
});

export default InputLabel;
