import { useState } from 'react';
import useForm, { FormInputs, FormValues } from 'evcharging/hooks/useForm';
import { ResponsePopupProps } from 'evcharging/components/ResponsePopup';
import useI18n from 'evcharging/hooks/useI18n';

enum Status {
  success = 'success',
  error = 'error',
}

interface Options {
  successMessage?: string;
}

const initialResponse = {
  message: '',
  type: Status.success,
};

const useFormResponse = (
  inputs: FormInputs,
  action: (values: FormValues) => Promise<void>,
  options: Options = {},
) => {
  const { onSubmit, isLoading, clear } = useForm(inputs);
  const [response, setResponse] = useState<ResponsePopupProps>(initialResponse);
  const { t } = useI18n();
  const { successMessage = 'Your data was submitted successfully' } = options;

  const onSuccess = async (values: FormValues) => {
    await action(values);
    clear();
    setResponse({
      message: successMessage,
      type: 'success',
    });
  };

  const onError = (e: Error) => {
    setResponse({ message: t(e.message), type: 'error' });
  };

  const onTime = () => {
    setResponse(initialResponse);
  };

  return {
    onSubmit: onSubmit({ onSuccess, onError }),
    isLoading,
    onTime,
    ...response,
  };
};

export default useFormResponse;
