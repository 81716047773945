import React, { FC } from 'react';
import { css } from '@emotion/css';

interface InputErrorProps {
  message?: string;
}

const InputError: FC<InputErrorProps> = (props) => {
  const { message } = props;
  return message ? <div className={styles.root}>{message}</div> : null;
};

const styles = {
  root: css`
    position: absolute;
    left: 5%;
    top: 85%;
    color: #fff;
    font-size: 0.9em;
    margin-bottom: 10px;
    z-index: 1;
    background: #ff4476;
    border-radius: 4px;
    padding: 0.6em 1.2em;

    :first-letter {
      text-transform: capitalize;
    }

    :after {
      content: '';
      position: absolute;
      left: 20px;
      bottom: 100%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent #ff4476;
    }
  `,
};

export default InputError;
