import { FormEventHandler, useState } from 'react';
import {
  InternalServerException,
  UnprocessableEntityException,
} from 'evcharging/app/http/exceptions';
import { UseInputOutput } from 'evcharging/hooks/useInput';
import useI18n from 'evcharging/hooks/useI18n';
import errors from 'evcharging/app/errors';

export type FormValues = Record<string, string>;
export type FormInputs = Record<string, UseInputOutput>;

interface OnSubmitParams {
  onSuccess: (values: FormValues) => Promise<void>;
  onError: (e: Error) => void;
}

const useForm = (inputs: FormInputs) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useI18n();

  const setErrors = (errors: Record<string, string>) => {
    Object.keys(inputs).forEach((name) => {
      inputs[name].setError(errors[name] ?? '');
    });
  };

  const onSubmit =
    ({ onSuccess, onError }: OnSubmitParams): FormEventHandler =>
    async (event) => {
      event.preventDefault();
      try {
        setIsLoading(true);
        setErrors({});
        await onSuccess(
          Object.entries(inputs).reduce<FormValues>(
            (accum, [name, input]) => ({ ...accum, [name]: input.value }),
            {},
          ),
        );
      } catch (e) {
        if (e instanceof UnprocessableEntityException) {
          setErrors(
            Object.keys(e.errors).reduce<Record<string, string>>((acc, key) => {
              // @ts-ignore
              acc[key] = t(errors[e.errors[key]]);
              return acc;
            }, {}),
          );
        } else if (e instanceof InternalServerException) {
          onError(new Error(t(e.message)));
        } else {
          throw e;
        }
      } finally {
        setIsLoading(false);
      }
    };

  const clear = () =>
    Object.entries(inputs).forEach(([, input]) => {
      input.setError('');
      input.onChangeValue('');
    });

  return { onSubmit, isLoading, clear };
};

export default useForm;
