export class UnprocessableEntityException {
  constructor(readonly errors: Record<string, string>) {}
}

export class InternalServerException {
  constructor(readonly message = 'form.response.500') {}
}

export class UnauthorizedException {
  constructor(readonly message = 'form.response.401') {}
}

export class ForbiddenException {
  constructor(readonly message = 'form.response.403') {}
}

export class MethodNotAllowedException {
  constructor(readonly allowedMethods: string[]) {}
}
