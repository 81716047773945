import { EMAIL_REGEX } from 'evcharging/app/validation/regex';

export const isEmpty = (value: any) => ['', null, undefined].includes(value);

export const isRequired = (value: any) => !isEmpty(value);

export const isEmail = (value: string) => EMAIL_REGEX.test(value);

export const isPassword = (value: string) =>
  value.length >= 7 && value.length <= 20;

export const isRequiredContacts = (value: any, values?: Record<string, any>) =>
  isRequired(values?.phone) || isRequired(values?.email);
