import { useState } from 'react';

export interface UseInputOutput<Value = string> {
  value: Value;
  error: string;
  onChangeValue: (value: Value) => void;
  setError: (code: string) => void;
  clear: () => void;
}

const useInput = <Value = string>(
  filters: Array<(value: Value) => Value> = [],
  initialState: any = '',
): UseInputOutput<Value> => {
  const [value, setValue] = useState<Value>(initialState);
  const [error, setError] = useState<string>('');

  const onChangeValue = (value: Value) => {
    setValue(filters.reduce((next, filter) => filter(next), value));
  };

  const clear = () => onChangeValue(initialState);

  return { value, error, onChangeValue, setError, clear };
};

export default useInput;
