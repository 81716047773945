import React from 'react';
import { Theme } from 'evcharging/app/themes';
import { css, keyframes } from '@emotion/css';
import useTheme from 'evcharging/hooks/useTheme';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Loader = () => {
  const theme = useTheme();
  const styles = createStyles(theme);
  return <div className={styles.root} />;
};

const createStyles = (theme: Theme) => ({
  root: css`
    border: 4px solid ${theme.colors.accent};
    border-top: 4px solid ${theme.colors.background.light};
    border-right: 4px solid ${theme.colors.background.light};
    border-bottom: 4px solid ${theme.colors.background.light};
    border-radius: 50%;
    width: 36px;
    height: 36px;
    animation: ${spin} 1s linear infinite;
  `,
});

export default Loader;
