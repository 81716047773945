import React, { FC, useEffect } from 'react';
import { css } from '@emotion/css';
import { Theme } from 'evcharging/app/themes';
import useTheme from 'evcharging/hooks/useTheme';
import clsx from 'clsx';

export interface ResponsePopupProps {
  message: string;
  type: 'success' | 'error';
  onTime?: () => void;
}

const ResponsePopup: FC<ResponsePopupProps> = (props) => {
  const { message, type, onTime } = props;
  const theme = useTheme();
  const styles = createStyles(theme, props);

  useEffect(() => {
    if (message && onTime) {
      setTimeout(onTime, 4000);
    }
  }, [message]);

  return <div className={clsx(styles.root, styles[type])}>{message}</div>;
};

const createStyles = (theme: Theme, { message }: ResponsePopupProps) => ({
  root: css`
    position: fixed;
    color: #fff;
    top: 120px;
    right: ${message ? '50px' : '-100px'};
    transition: right 200ms;
    padding: 20px;
    border-radius: 5px;
    z-index: 1100;
  `,
  success: css`
    background: #31d931;
  `,
  error: css`
    background: #ff4476;
  `,
});

export default ResponsePopup;
